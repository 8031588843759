import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Layout from "../../layout/layout";
import axios from "../../api/axios";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Snackbar,
  Alert,
  Switch,
} from "@mui/material";
// import MuiAlert from '@mui/material/Alert';
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useLocation } from "react-router-dom";

export default function CreateCoupon() {
  const location = useLocation();

  const [couponId, setCouponId] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("FV");
  const [couponValue, setCouponValue] = useState(0);
  const [usageLimit, setUsageLimit] = useState(1);
  const [usageCount, setUsageCount] = useState(0);
  const [validity, setValidity] = useState(null);
  const [dynamicValues, setDynamicValues] = useState([
    { from: "", to: "", discountValue: "" },
  ]);
  const [isActive, setActive] = useState(false);
  // create state for package type
  const [packageTypes, setPackageTypes] = useState(["LF", "BD", "SC", "LB","OT"]);
  // create state for packageDurations
  const [packageDurations, setPackageDurations] = useState([1, 6, 12, 26]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [isEditMode, setEditMode] = useState(false);

  useEffect(() => {
    handleFormValidation(); // Call handleFormValidation whenever any relevant state changes
  }, [
    couponCode,
    couponType,
    couponValue,
    usageLimit,
    dynamicValues,
    validity,
  ]);

  useEffect(() => {
    const coupon = location.state;
    if (coupon) {
      setEditMode(true);
      setCouponId(coupon.id);
      setCouponCode(coupon.code);
      setCouponType(coupon.type);
      setCouponValue(coupon.discount);
      setUsageLimit(coupon.usage_limit);
      setUsageCount(coupon.usage_count);
      setValidity(coupon.validity ? new Date(coupon.validity) : null);
      setDynamicValues(
        coupon.dynamic_value ?? [{ from: "", to: "", discountValue: "" }]
      );
      setPackageTypes(coupon.packageTypes ?? []);
      setPackageDurations(coupon.package_durations ?? []);

      setActive(coupon.is_active);
    }
  }, [location]);

  const handleAddDynamicValue = () => {
    setDynamicValues([
      ...dynamicValues,
      { from: "", to: "", discountValue: "" },
    ]);
  };

  const handleRemoveDynamicValue = (index) => {
    const values = [...dynamicValues];
    values.splice(index, 1);
    setDynamicValues(values);
  };

  const handleInputChange = (e, index, field) => {
    const values = [...dynamicValues];
    values[index][field] = e.target.value;
    setDynamicValues(values);
  };

  const handleSubmit = async () => {
    handleFormValidation();
    if (!isFormValid) {
      console.log("Form is not valid!");
    }

    console.log("Form submitted!");
    console.log(validity.toISOString().split("T")[0]);
    const data = {
      id: couponId,
      coupon_code: couponCode,
      coupon_type: couponType,
      coupon_value: couponValue,
      usage_limit: usageLimit,
      validity: validity.toISOString().split("T")[0],
      package_types: packageTypes,
      package_durations: packageDurations,
      dynamic_value: dynamicValues
        ? dynamicValues.filter((v) => v.discountValue && v.from && v.to)
        : null,
      is_active: isActive,
    };
    try {
      if (!isEditMode) {
        const response = await axios.post("coupon/create/", data);
        console.log(response);
        if (response.status === 200) {
          setAlertOpen(true);
          setAlertMessage(response.data.msg);
          setAlertSeverity("success");
        } else {
          setAlertOpen(true);
          setAlertMessage(response.data.msg);
          setAlertSeverity("error");
        }
      } else {
        const response = await axios.post("coupon/update/", data);
        console.log(response);
        if (response.status === 200) {
          setAlertOpen(true);
          setAlertMessage(response.data.msg);
          setAlertSeverity("success");
        } else {
          setAlertOpen(true);
          setAlertMessage("Something went wrong!");
          setAlertSeverity("error");
        }
      }
    } catch (err) {
      console.log(err);
      setAlertOpen(true);
      setAlertMessage("Coupon already exists");
      setAlertSeverity("error");
    }
  };

  function handleFormValidation() {
    const isDynamicValuesValid =
      dynamicValues &&
      dynamicValues.length > 0 &&
      dynamicValues.every(
        (value) => value.from && value.to && value.discountValue
      );

    const isFormFieldsValid =
      couponCode &&
      usageLimit &&
      validity &&
      ((couponType === "DV" && isDynamicValuesValid) ||
        ((couponType === "FV" || couponType === "PR") && couponValue >= 0));

    setIsFormValid(isFormFieldsValid);
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const body = (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <form>
        <Box sx={{ width: "50%" }}>
          <TextField
            label="Coupon code"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
            required
            disabled={isEditMode}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel sx={{ fontWeight: 500 }} required>
              Coupon type
            </InputLabel>
            <Select
              value={couponType}
              onChange={(e) => setCouponType(e.target.value)}
              input={<OutlinedInput label="Coupon Types" />}
            >
              <MenuItem value="FV">Fixed Values</MenuItem>
              <MenuItem value="PR">Percentage</MenuItem>
              <MenuItem value="DV">Dynamic Values</MenuItem>
            </Select>
          </FormControl>
          {(couponType === "FV" || couponType === "PR") && (
            <TextField
              label="Coupon value"
              value={couponValue}
              onChange={(e) => setCouponValue(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
              required
            />
          )}
          <TextField
            label="Usage limit"
            type="number"
            value={usageLimit}
            onChange={(e) => setUsageLimit(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
            required
            InputProps={{
              inputProps: {
                min: 2,
              },
            }}
          />
          {packageTypes && (
            <FormControl sx={{ width: 303, mb: 2, mr: 2 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Package Types
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={packageTypes}
                onChange={(e) => {
                  setPackageTypes(
                    typeof e.target.value === "string"
                      ? e.target.value.split(",")
                      : e.target.value
                  );
                }}
                input={<OutlinedInput label="Package Types" />}
                renderValue={(selected) => selected.join(", ")}
                // MenuProps={MenuProps}
              >
                <MenuItem key={"LF"} value={"LF"}>
                  <Checkbox checked={packageTypes.indexOf("LF") > -1} />
                  <ListItemText primary={"Lifestyle"} />
                </MenuItem>
                <MenuItem key={"BD"} value={"BD"}>
                  <Checkbox checked={packageTypes.indexOf("BD") > -1} />
                  <ListItemText primary={"Bodybuilding"} />
                </MenuItem>
                <MenuItem key={"SC"} value={"SC"}>
                  <Checkbox checked={packageTypes.indexOf("SC") > -1} />
                  <ListItemText primary={"Scale"} />
                </MenuItem>
                <MenuItem key={"LB"} value={"LB"}>
                  <Checkbox checked={packageTypes.indexOf("LB") > -1} />
                  <ListItemText primary={"LunchBox"} />
                </MenuItem>
                <MenuItem key={"OT"} value={"OT"}>
                  <Checkbox checked={packageTypes.indexOf("OT") > -1} />
                  <ListItemText primary={"1100Calories"} />
                </MenuItem>
              </Select>
            </FormControl>
          )}

          {packageDurations && (
            <FormControl sx={{ width: 303, mb: 2 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Package Durations
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={packageDurations}
                onChange={(e) => {
                  setPackageDurations(
                    typeof e.target.value === "number"
                      ? e.target.value.split(",")
                      : e.target.value
                  );
                }}
                input={<OutlinedInput label="Package Durations" />}
                renderValue={(selected) => selected.join(", ")}
                // MenuProps={MenuProps}
              >
                <MenuItem key={1} value={1}>
                  <Checkbox checked={packageDurations.indexOf(1) > -1} />
                  <ListItemText primary={"1 day"} />
                </MenuItem>
                <MenuItem key={6} value={6}>
                  <Checkbox checked={packageDurations.indexOf(6) > -1} />
                  <ListItemText primary={"6 day"} />
                </MenuItem>
                <MenuItem key={12} value={12}>
                  <Checkbox checked={packageDurations.indexOf(12) > -1} />
                  <ListItemText primary={"12 day"} />
                </MenuItem>
                <MenuItem key={26} value={26}>
                  <Checkbox checked={packageDurations.indexOf(26) > -1} />
                  <ListItemText primary={"26 day"} />
                </MenuItem>
                <MenuItem key={20} value={20}>
                  <Checkbox checked={packageDurations.indexOf(20) > -1} />
                  <ListItemText primary={"20 day (no weekends)"} />
                </MenuItem>
              </Select>
            </FormControl>
          )}

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              label="Validity"
              value={validity}
              onChange={(date) => setValidity(date)}
              fullWidth
              renderInput={(props) => <TextField {...props} fullWidth />}
              disablePast
            />
          </LocalizationProvider>

          {isEditMode && (
            <Box sx={{ mt: 2 }}>
              <Switch
                checked={isActive}
                onChange={() => {
                  setActive(!isActive);
                }}
              />
              {"Active"}
            </Box>
          )}

          <Box sx={{ mb: 2 }} />

          {couponType === "DV" && (
            <div>
              {dynamicValues.map((value, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div>
                    <TextField
                      label="From"
                      type="number"
                      value={value.from}
                      onChange={(e) => handleInputChange(e, index, "from")}
                      sx={{ mt: 1, mb: 1, mr: 2 }}
                    />
                    <TextField
                      label="To"
                      type="number"
                      value={value.to}
                      onChange={(e) => handleInputChange(e, index, "to")}
                      sx={{ mt: 1, mb: 1, mr: 2 }}
                    />
                    <TextField
                      label="Discount Value"
                      type="number"
                      value={value.discountValue}
                      onChange={(e) =>
                        handleInputChange(e, index, "discountValue")
                      }
                      sx={{ mt: 1, mb: 1, mr: 2 }}
                    />
                    <IconButton
                      onClick={() => handleRemoveDynamicValue(index)}
                      sx={{ color: "red" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Box>
              ))}
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleAddDynamicValue}
                sx={{ mb: 2, mt: 2 }}
              >
                Add Dynamic Coupon Values
              </Button>
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
            disabled={!isFormValid}
          >
            {isEditMode ? "Update" : "Submit"}
          </Button>
        </Box>
      </form>
    </>
  );

  return (
    <>
      <Layout
        body={body}
        title={isEditMode ? "Edit Coupon" : "Create Coupon"}
      />
    </>
  );
}
